import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { Text, Button, TileWrapper, Input, analytics } from 'lib'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Grid, Select, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, RadioGroup, FormControlLabel, Radio, InputAdornment } from '@material-ui/core'
import firebase from 'gatsby-plugin-firebase'
import emailValidator from 'email-validator'

import ReCAPTCHA from 'react-google-recaptcha'

import { ALL_COUNTRIES, ALL_US_STATES } from './_territories'

const Signup = (props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('US')
  const [USState, setUSState] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [referralCodeStatus, setReferralCodeStatus] = useState(null)
  const [isBusinessAccount, setIsBusinessAccount] = useState('no')
  const [businessName, setBusinessName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [subPage, setSubPage] = useState(0)

  // null, LOADING, EMAIL_EXISTS, WRONG_TERRITORY
  const [status, setStatus] = useState(null)

  const [valid, setValid] = useState({
    email: true,
    captcha: false,
    phoneNumber: false,
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search)

    const refParam = urlParams.get('ref')
    const accountTypeParam = urlParams.get('accountType')

    if (refParam?.length == 10) {
      setReferralCode(refParam)
      checkReferralCode()
    }

    if (accountTypeParam == 'business') {
      setIsBusinessAccount('yes')
    }
  }, [])

  const styles = useStyles()

  const onNext = async () => {

    if (window && typeof window !== 'undefined' && !__DEV__) window.fbq('track', 'Sign up attempted', { email })

    setStatus('LOADING')

    try {
      const signupInfo = { firstName, lastName, email: email.toLowerCase(), country, USState, businessName, phoneNumber, referralCode: referralCodeStatus == 'CONFIRMED' ? referralCode : undefined }
      analytics.track('Location Next Button', { signupInfo })

      const serverObjFunc = firebase.functions().httpsCallable('checkSignupEligibility')

      const eligibilityStatus = await serverObjFunc(signupInfo)

      const result = eligibilityStatus?.data?.result

      if (result == 'EMAIL_EXISTS') {
        setStatus('EMAIL_EXISTS')
      } else if (result == 'WRONG_TERRITORY') {
        setStatus('WRONG_TERRITORY')
      } else if (result == 'SUCCESS') {
        navigate('/signup/setPW', { state: signupInfo })
      } else {
        alert('Error signing up. Please refresh the page and try again.')
        setStatus(null)
      }

    } catch (e) {
      log(e)
    }

  }

  const checkReferralCode = async () => {
    setReferralCodeStatus('LOADING')

    analytics.track('Apply Referral Code')

    try {
      const serverObjFunc = firebase.functions().httpsCallable('checkReferralCode')
      const codeStatus = await serverObjFunc({ code: referralCode.toUpperCase() })

      const codeIsOkay = codeStatus?.data?.result

      if (codeIsOkay) {

        setReferralCodeStatus('CONFIRMED')
      } else {
        setReferralCodeStatus('INCORRECT')
      }

    } catch (e) {
      setReferralCodeStatus('INCORRECT')
      log(e)
    }

  }

  const validate = (name, value) => {
    switch (name) {
      case 'email':
        setValid({ ...valid, email: emailValidator.validate(value) })
        break
      case 'phoneNumber':
        setValid({ ...valid, phoneNumber: phoneNumber.length > 0 && phoneNumber.length <= 10 })
        break
      default:
        break
    }
  }

  const handleChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    switch (name) {
      case 'email':
        setEmail(value)
        break
      case 'firstName':
        setFirstName(value)
        break
      case 'lastName':
        setLastName(value)
        break
      case 'country':
        setCountry(value)
        if (value != 'US') setUSState('')
        break
      case 'USState':
        setUSState(value)
        break
      case 'referralCode':
        setReferralCode(value)
        setReferralCodeStatus(null)
        break
      case 'accountType':
        setIsBusinessAccount(value)
        break
      case 'businessName':
        setBusinessName(value)
        break
      case 'phoneNumber':
        setPhoneNumber(value)
        break
    }

    validate(name, value)
  }

  const WrongTerritoryMessage = () => {
    const territoryName = country == 'US' ?
      ALL_US_STATES.find(s => s.code == USState).name :
      ALL_COUNTRIES.find(c => c.code == country).name

    return (
      <div className={styles.center}>
        <Text variant={'h5'} text={'Thank you for your interest in Stablegains!'} gutterBottom style={styles.title} align='center'/>
        <Text text={`Unfortunately, Stablegains is not yet available in ${territoryName}.`} align='center' style={styles.message}/>
        <Text text={`We'll email you as soon as we have an update!`} align='center' style={styles.message}/>
      </div>
    )
  }

  const captchaValidated = () => setValid({ ...valid, captcha: true })

  const validRefreshed = valid

  const phoneValid = phoneNumber.length == 10

  const page0valid = () => {
    return validRefreshed.email
      && email.length > 0
      && firstName.length > 0
      && lastName.length > 0
      && (isBusinessAccount == 'no' || (businessName.length > 0 && phoneValid))
  }

  const page1valid = () => {
    return country.length
    && (country != 'US' || USState.length)
    && valid.captcha
    && !status
  }

  const countryOfResidenceLabel = isBusinessAccount == 'yes' ? 'Which country is your business incorporated in?' : 'Which country do you live in?'

  return (
    <TileWrapper large>
      {status == 'WRONG_TERRITORY' ? <WrongTerritoryMessage/> :
        <>

          <Grid container
            justify='center'
            alignItems='center'
            className={styles.formWrapper}
            spacing={2}
          >

            {subPage == 0 ? (
              <>
                <Grid item xs={12}>
                  <>
                    <Text variant={'h6'} text={`Start earning with Stablegains`} align='center' style={styles.heading}/>
                    {referralCodeStatus == 'CONFIRMED' && <Text text={`Sign up now and get your $25 bonus!`} align='center' style={styles.subheading}/>}
                  </>
                </Grid>

                <Grid item xs={12}>
                  <Grid container
                    justify='center'
                    alignItems='stretch'
                    spacing={2}>
                    <Grid item xs={6}>
                      <Input
                        autoFocus
                        value={firstName}
                        onChange={handleChange}
                        name='firstName'
                        label={'First name'}
                        size='small'
                        fullWidth
                        margin='none'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={lastName}
                        onChange={handleChange}
                        name='lastName'
                        label={'Last name'}
                        size='small'
                        fullWidth
                        margin='none'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Input
                        value={email}
                        onChange={handleChange}
                        type='email'
                        name='email'
                        size='small'
                        label={'Email address'}
                        fullWidth
                        margin='none'
                        error={!valid.email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component='fieldset' className={styles.accountTypeSelector}>
                        <Text text={'What type of account are you opening?'}/>
                        <RadioGroup row aria-label='accountType' name='accountType' value={isBusinessAccount} onChange={handleChange}>
                          <FormControlLabel value={'no'} control={<Radio/>} label='Personal'/>
                          <FormControlLabel value={'yes'} control={<Radio/>} label='Business'/>
                        </RadioGroup>
                      </FormControl>

                    </Grid>

                    {isBusinessAccount == 'yes' ? (
                      <>

                        <Grid item xs={12}>
                          <Input
                            value={businessName}
                            onChange={handleChange}
                            name='businessName'
                            size='small'
                            label={'What is the name of your business?'}
                            fullWidth
                            margin='none'
                            helperText={'Please include parts such as Inc. or LLC.'}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Input
                            value={phoneNumber}
                            onChange={handleChange}
                            type='number'
                            name='phoneNumber'
                            size='small'
                            label={'What is your phone number?'}
                            fullWidth
                            margin='none'
                            InputProps={{
                              startAdornment: <InputAdornment position='start'>+1</InputAdornment>,
                            }}
                            error={phoneNumber.length && !phoneValid}
                          />
                        </Grid>

                      </>

                    ) : null}


                    <Grid item xs={12}>
                      <Button disabled={!page0valid() || referralCodeStatus == 'LOADING'} style={styles.submitButton} variant='contained' size='large' color='secondary' text={'NEXT'} onClick={() => {
                        analytics.track('Sign-up Button', {
                          signupInfo: { firstName, lastName, email, referralCode: referralCodeStatus == 'CONFIRMED' ? referralCode : undefined },
                        })
                        setSubPage(1)
                      }} fullWidth/>
                    </Grid>

                    <Text variant={'subtitle2'} align='center'>Already have an account? <Link test to={'/login'}>Sign in</Link></Text>
                  </Grid>
                </Grid>

              </>

            ) : (
              <>
                <Grid item xs={12}>
                  <Text variant={'h6'} text={`Nice to meet you, ${firstName}!`} align='center' style={styles.heading}/>
                </Grid>

                <Grid item>
                  <Grid container
                    direction='column'
                    justify='center'
                    alignItems='stretch'
                    spacing={2}>

                    <Grid item xs={12}>
                      <FormControl variant='outlined' size='small' fullWidth>
                        <InputLabel>{countryOfResidenceLabel}</InputLabel>
                        <Select
                          native
                          value={country}
                          onChange={handleChange}
                          name='country'
                          label={countryOfResidenceLabel}
                        >
                          {ALL_COUNTRIES.map(c => <option key={c.code} value={c.code}>{c.name}</option>)}
                        </Select>
                      </FormControl>
                    </Grid>

                    {country == 'US' && (

                      <Grid item xs={12} alignContent='stretch'>
                        <FormControl variant='outlined' size='small' fullWidth>
                          <InputLabel>Which state/territory?</InputLabel>
                          <Select
                            native
                            value={USState}
                            onChange={handleChange}
                            name='USState'
                            label={'Which state/territory?'}
                          >
                            <option aria-label='None' value=''/>
                            {ALL_US_STATES.map(c => <option key={c.code} value={c.code}>{c.name}</option>)}
                          </Select>
                        </FormControl>
                      </Grid>

                    )}
                    <Grid item xs={12}>

                      <Grid container
                        justify='center'
                        alignItems='center'>

                        <div style={{ marginTop: 16, marginLeft: 'auto', marginRight: 'auto', height: 78 }}>

                          <ReCAPTCHA
                            sitekey={process.env.CAPTCHA_SITE_KEY}
                            onChange={captchaValidated}
                          />

                        </div>

                      </Grid>

                    </Grid>


                    <Grid item xs={12} className={styles.buttonWrapper}>

                      <Button disabled={!page1valid()} loading={status == 'LOADING'} style={styles.submitButton} variant='contained' size='large' color='secondary' text={'NEXT'} onClick={onNext} fullWidth/>
                      <Button variant='none' style={styles.backButton} size='large' color='secondary' text={'Back'} onClick={() => setSubPage(0)}/>

                    </Grid>

                  </Grid>
                </Grid>


              </>


            )}

          </Grid>
        </>
      }

      <Dialog
        open={status == 'EMAIL_EXISTS'}
        onClose={() => setStatus(null)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Email already registered'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            An account with this email already exists. You can sign in or use another email.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate('/login')} color='primary'>
            Sign in
          </Button>
          <Button onClick={() => {
            setEmail('')
            setStatus(null)
          } } color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </TileWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  message: {
    // opacity: 0.5,
    textAlign: 'center',
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  subheading: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    alignSelf: 'center',
    textAlign: 'center',
  },
  backButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    alignSelf: 'center',
    textAlign: 'center',
  },
  listError: {
    color: 'red!important',
  },
  list: {
    margin: 0,
    marginTop: 15,
    paddingLeft: 16,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  circularWrapper: {
    textAlign: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formWrapper: {
    maxWidth: 520,
  },
  accountTypeSelector: {
    marginTop: theme.spacing(1),
  },
}))

export default Signup
